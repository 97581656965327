
        <template>
          <div class="vc-snippet-doc">
            <h2>Drawer 抽屉</h2>
<p>有些时候, Dialog 组件并不满足我们的需求, 比如你的表单很长, 亦或是你需要临时展示一些文档, Drawer 拥有和 Dialog 几乎相同的 API, 在 UI 上带来不一样的体验.</p>
<h3>基本用法</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">eui-button</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> @<span class="hljs-attr">click</span>=<span class="hljs-string">"v1 = true"</span>&gt;</span>打开抽屉<span class="hljs-tag">&lt;/<span class="hljs-name">eui-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-drawer</span> <span class="hljs-attr">:open.sync</span>=<span class="hljs-string">"v1"</span> @<span class="hljs-attr">save</span>=<span class="hljs-string">"save"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-drawer</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">v1</span>: <span class="hljs-literal">false</span>
        }
    },
    <span class="hljs-attr">methods</span>: {
        save(file) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'file:'</span>, file)
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>多层嵌套</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">eui-button</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> @<span class="hljs-attr">click</span>=<span class="hljs-string">"v1 = true"</span>&gt;</span>打开抽屉<span class="hljs-tag">&lt;/<span class="hljs-name">eui-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-drawer</span> <span class="hljs-attr">:open.sync</span>=<span class="hljs-string">"v1"</span> @<span class="hljs-attr">save</span>=<span class="hljs-string">"save"</span> <span class="hljs-attr">title</span>=<span class="hljs-string">"v1"</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"600px"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">eui-button</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> @<span class="hljs-attr">click</span>=<span class="hljs-string">"v2 = true"</span>&gt;</span>打开抽屉<span class="hljs-tag">&lt;/<span class="hljs-name">eui-button</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-drawer</span> <span class="hljs-attr">:open.sync</span>=<span class="hljs-string">"v2"</span> @<span class="hljs-attr">save</span>=<span class="hljs-string">"save"</span> <span class="hljs-attr">title</span>=<span class="hljs-string">"v2"</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"400px"</span>&gt;</span>
            <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-drawer</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-drawer</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">v1</span>: <span class="hljs-literal">false</span>,
            <span class="hljs-attr">v2</span>: <span class="hljs-literal">false</span>
        }
    },
    <span class="hljs-attr">methods</span>: {
        save(file) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'file:'</span>, file)
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Drawer Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>append-to-body</td>
<td>Drawer 自身是否插入至 body 元素上。嵌套的 Drawer 必须指定该属性并赋值为 true</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>before-close</td>
<td>关闭前的回调，会暂停 Drawer 的关闭</td>
<td>function(done)，done 用于关闭 Drawer</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>close-on-press-escape</td>
<td>是否可以通过按下 ESC 关闭 Drawer</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>custom-class</td>
<td>Drawer 的自定义类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>destroy-on-close</td>
<td>控制是否在关闭 Drawer 之后将子元素全部销毁</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>modal</td>
<td>是否需要遮罩层</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>modal-append-to-body</td>
<td>遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Drawer 的父元素上</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>direction</td>
<td>Drawer 打开的方向</td>
<td>Direction</td>
<td>rtl / ltr / ttb / btt</td>
<td>rtl</td>
</tr>
<tr>
<td>show-close</td>
<td>是否显示关闭按钮</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>size</td>
<td>Drawer 窗体的大小, 当使用 <code>number</code> 类型时, 以像素为单位, 当使用 <code>string</code> 类型时, 请传入 'x%', 否则便会以 <code>number</code> 类型解释</td>
<td>number / string</td>
<td>-</td>
<td>'30%'</td>
</tr>
<tr>
<td>title</td>
<td>Drawer 的标题，也可通过具名 slot （见下表）传入</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>visible</td>
<td>是否显示 Drawer，支持 .sync 修饰符</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>wrapperClosable</td>
<td>点击遮罩层是否可以关闭 Drawer</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
<tr>
<td>withHeader</td>
<td>控制是否显示 header 栏, 默认为 true, 当此项为 false 时, title attribute 和 title slot 均不生效</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
</tbody>
</table>
<h3>Drawer Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>—</td>
<td>Drawer 的内容</td>
</tr>
<tr>
<td>title</td>
<td>Drawer 标题区的内容</td>
</tr>
</tbody>
</table>
<h3>Drawer Methods</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>closeDrawer</td>
<td>用于关闭 Drawer, 该方法会调用传入的 <code>before-close</code> 方法</td>
</tr>
</tbody>
</table>
<h3>Drawer Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>open</td>
<td>Drawer 打开的回调</td>
<td>—</td>
</tr>
<tr>
<td>opened</td>
<td>Drawer 打开动画结束时的回调</td>
<td>—</td>
</tr>
<tr>
<td>close</td>
<td>Drawer 关闭的回调</td>
<td>—</td>
</tr>
<tr>
<td>closed</td>
<td>Drawer 关闭动画结束时的回调</td>
<td>—</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('eui-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.v1 = true}}},[_vm._v("打开抽屉")]),_vm._v(" "),_c('ixu-drawer',{attrs:{"open":_vm.v1},on:{"update:open":function($event){_vm.v1=$event},"save":_vm.save}})],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            v1: false
        }
    },
    methods: {
        save(file) {
            console.log('file:', file)
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('eui-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.v1 = true}}},[_vm._v("打开抽屉")]),_vm._v(" "),_c('ixu-drawer',{attrs:{"open":_vm.v1,"title":"v1","width":"600px"},on:{"update:open":function($event){_vm.v1=$event},"save":_vm.save}},[_c('eui-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.v2 = true}}},[_vm._v("打开抽屉")]),_vm._v(" "),_c('ixu-drawer',{attrs:{"open":_vm.v2,"title":"v2","width":"400px"},on:{"update:open":function($event){_vm.v2=$event},"save":_vm.save}})],1)],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            v1: false,
            v2: false
        }
    },
    methods: {
        save(file) {
            console.log('file:', file)
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>